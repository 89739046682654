@import 'global.scss';

.wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}
.icon {
  display: flex;
  align-items: center;
}
.label {
  @include paragraph-p4;
  color: $color-base-900;
}
.value {
  @include label-l3-m;
  color: $color-base-900;
}
.confirmation {
  margin-top: $gap-mobile;
}

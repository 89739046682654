@import 'global.scss';

.container {
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &::before {
    content: '';
    height: 1px;
    width: 100%;
    background-color: $color-base-400;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}

.title {
  @include heading-h5;
  color: $color-base-900;
}

.number {
  @include short-text-t4;
  color: $color-base-900;
}

.timer {
  @include label-l5-m;
  color: $color-base-700;
}

.block {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  gap: 12px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 12px;

  &__title {
    @include label-l4;
    color: $color-base-900;
  }
  &__number {
    @include heading-h4;
    color: $color-base-900;
  }
}

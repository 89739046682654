@import 'global.scss';

.root {
  height: 48px;
  border-radius: 12px;
  background-color: $color-magenta-100;
  color: $color-base-0;
  outline: none;
  width: 100%;
  flex-shrink: 0;

  &:hover,
  &:active {
    background-color: $color-magenta-200;
  }

  &[data-disabled] {
    background-color: $color-base-200;
    color: $color-base-500;
  }

  &:focus, :focus-visible {
    outline: none;
  }
}

.label {
  &__short {
    @include label-l2-m;
  }
  &__long {
    @include label-l4-m;
  }
}

@import 'global.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.title {
  height: 28px;
  display: flex;
  align-items: flex-start;
  @include heading-h5;
  color: $color-base-900;
}

@import 'global.scss';

.container {
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &::before {
    content: '';
    height: 1px;
    width: 100%;
    background-color: $color-base-400;
  }
}

.title {
  @include heading-h5;
  color: $color-base-900;
}

@import '@/styles/global.scss';

.container {
  position: absolute;
  padding: 32px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: $corner-radius-large;
  background: #fff;
  box-shadow: 0px 25px 45px 0px rgba(52, 86, 251, 0.2);
  width: 303px;
  bottom: 16px;
  left: 16px;
  z-index: $z-index-map-object-info;
  overflow: hidden;

  @include tablet {
    bottom: inherit;
    top: 16px;
    width: 335px;
  }

  &::before {
    @include pink-bg-circle;
    top: -125px;
  }
  &::after {
    @include purple-bg-circle;
    top: 240px;
    left: -97px;
  }
}

.centered {
  top: calc((100% - 206px) / 2);
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    max-width: 260px;
    @include heading-h4;
    margin: 0;
  }
  &__street {
    @include heading-h5;
    margin: 0;
  }
}

.time {
  @include paragraph-p4;
}

.closeBtn {
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  height: 24px;
  width: 24px;
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1;
}

.bottomsheet {
  padding: 0 20px 24px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__root {
    position: absolute;
    z-index: 500;

    div[aria-modal='true'] {
      box-shadow: 0px -15px 30px 0px rgba(52, 86, 251, 0.15);
    }
  }

  .header {
    &__title { 
      margin-top: 8px;
      max-width: inherit;
    }
    &__street {
      margin-bottom: 8px;
    }
  }

  @include tablet {
    padding: 0;
  }
}

.button {
  margin-top: 24px;
  width: 100%;
  background-color: $color-magenta-100;
  color: $color-base-0;
  border-radius: 12px;

  @include label-l4-m;

  &:hover {
    background-color: $color-magenta-200;
  }
  &:active {
    background-color: $color-magenta-0;
  }
  &:focus,
  :focus-visible {
    outline: none;
  }
}

.close {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.topShop {
  @include label-l6;
  width: fit-content;
  height: auto;
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $color-magenta-100;
  color: $color-base-0;
  padding: 8px 20px;
  border-bottom-right-radius: 16px;
}
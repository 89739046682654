@import 'global.scss';

.container {
  width: 100%;
  height: 640px;
  border-radius: $corner-radius-large;
  overflow: hidden;
  & > ymaps {
    width: 100% !important;
    & > ymaps {
      width: 100% !important;
    }
  }
}

.placemark {
  width: 31px;
  height: 31px;
  position: absolute;
  top: -20px;
  left: -15px;
}

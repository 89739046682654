@import 'global.scss';

.container {
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &::before {
    content: '';
    height: 1px;
    width: 100%;
    background-color: $color-base-400;
  }

  &__main {
    padding-top: 16px;
    padding-bottom: 0;
    &::before {
      display: none;
    }
  }
}

.title {
  @include heading-h5;
  color: $color-base-900;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    @include label-l6;
    color: $color-base-900;
  }
  &__first {
    padding-top: 8px;
  }
}

.price {
  &__actual {
    @include heading-h4;
    color: $color-base-900;
  }
  &__old {
    font-family: TT Norms Pro;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: $color-base-700;
    text-decoration: line-through;
  }
  &__base {
    @include heading-h5;
    color: $color-base-900;
  }
}

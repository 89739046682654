@import 'global.scss';

.serviceBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  user-select: none;

  &__left {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__leftTop {
    display: flex;
    gap: 4px;
  }

  &__title {
    color: $color-base-900;
    @include short-text-t4;
  }

  &__tooltip {
    display: inline-block;
  }

  &__titleRemoved {
    margin: 0;
    color: $color-base-600;
    @include short-text-t4;
  }

  &__price {
    @include label-l5-m;
    color: $color-base-900;
  }

  &__priceRemoved {
    @include label-l5-m;
    color: $color-base-600;

    .oldPrice {
      opacity: 0.6;
    }
  }
}
.option {
  &__tooltip {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__title {
      @include label-l3-m;
    }

    &__text {
      @include paragraph-p5;
    }
  }
  
  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 4px;
    height: 17px;
    width: 16px;
  }
}

.removed {
  padding-left: 4px;
  color: $color-error-fire;
}

.restoredButton {
  margin: 0;
  padding: 0;
  background: none;

  &:hover {
    background: none;
  }

  &:active {
    background: none;
  }
}

.oldPrice {
  @include label-l6;
  color: $color-base-700;
  text-decoration: line-through;
  margin-right: 4px;
}
@import 'global.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: $gap-mobile;
}

.map {
  position: relative;
  &__form {
    height: 256px;
  }
  &__error {
    border: 1px solid $color-error-fire;
    border-radius: $corner-radius-large;
  }
}

.list {
  color: $color-base-0;
  height: 20px;
  overflow: hidden;
}
